body {
  margin: 0px;
}
.dropzone {
  margin: auto;
  height: 80px;
  width: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.colorp {
  margin-left: auto;
  margin-top: 6px;
}
.errorClass {
  color: red !important;
  margin-top: 5px !important;
}
.errorClass1 {
  color: red !important;
  margin-top: 5px !important;
  font-size: 14px !important;
}
.blac-iconarrow {
  height: 14px;
  width: 14px;
  cursor: pointer;
  fill: black !important;
}
.grey-iconarrow {
  height: 14px;
  width: 14px;
  cursor: pointer;
  fill: #6d6d6d !important;
}
.arrange-container {
  padding: 4px;
  border: 1px solid black;
  border-radius: 8px;
  display: flex;
  margin: 0px 2px 8px 2px;
  &:hover {
    background-color: #f5f5f5;
  }
  &.required {
    background-color: #ebebe4;
  }
  &.select {
    background-color: #aeacac;
  }
}

.fc-day-other {
  & .fc-daygrid-day-frame {
    background-color: #f5f5f5 !important;
  }
}
.fc-day-today {
  background-color: white !important;
  & .fc-daygrid-day-frame {
    background-color: #fffadf !important;
  }
}
.fc td,
.fc th {
  border-style: none !important;
}
.fc-scrollgrid {
  border: none !important;
}
.fc-scrollgrid td:last-of-type {
  border-right: none !important;
}
.fc-header-toolbar {
  padding: 10px;
}
.mla {
  margin-left: auto !important;
}
.fg {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}
.fgmt {
  margin: auto 0;
  width: 150px;
}

.fgmt2 {
  margin: auto 0;
  max-width: 340px;
  width: 100%;
}
.mySelect {
  margin-top: 8px;
  border-radius: 2px !important;
  border-color: lightgray !important;
  & fieldset {
    border-color: lightgray !important;
    border-radius: 2px !important;
  }
}
.notlink {
  text-decoration: none;
}
.fc-event {
  background-color: transparent !important;
  border: none !important;
}
.matrix-wrapper {
  padding: 2em;
  margin-bottom: 2em;
  position: relative;

  .matrix {
    position: relative;
    min-height: 25em;
    z-index: 2;

    .x-axis {
      left: 0px;
      top: 50%;
      width: 100%;
      position: absolute;
      height: 50%;
      z-index: 1;
      text-align: right;
      border-top: 1px solid #000000;

      span {
        line-height: 2em;
      }
    }

    .y-axis {
      top: 0px;
      left: 0px;
      width: 50%;
      position: absolute;
      height: 100%;
      z-index: 1;
      border-right: 1px solid #000000;
    }

    .matrix-cell {
      width: 4em;
      font-size: 0.9em;
      height: 4em;
      margin-top: -2em;
      margin-left: -2em;
      z-index: 2;
      position: absolute;
      text-align: center;

      img {
        width: 70%;
        margin: auto;
        max-width: 70%;
      }
    }
  }
}
.y-axis-label {
  text-align: center;
  padding-top: 2em;
}
.sign {
  background-color: #ffed71;
  border: 1px solid #000;
  -webkit-transform: rotate(-5deg);
  transform: rotate(-5deg);
  box-shadow: 3px 3px 3px #aaa;
  color: #000;
  text-transform: uppercase;
  padding-left: 23px;
  display: flex;
  align-items: center;
  font-weight: 600;
  padding-right: 23px;
  height: 51px;
}
div.container {
  position: relative;

  textarea.value,
  textarea.essence {
    border: 1px solid grey;
    position: absolute;
    height: 9em;
    padding-left: 2.5em;
    padding-right: 2.5em;
    width: 40%;
  }

  textarea.value {
    margin-bottom: 2em;
    text-align: right;
    right: 0%;
    padding-top: 1em;

    &.left {
      text-align: left;
      left: 0px;
    }

    &.bottom {
      padding-top: 5em;
      top: 14em;
    }
  }

  textarea.essence {
    text-align: center;
    top: 7em;
    left: 50%;
    z-index: 2;
    padding-top: 3em;
    width: 50%;
    background-color: #ffffff;
    margin-left: -25%;
  }
}
.was {
  text-decoration: line-through;
}
.cursor1 {
  cursor: url("data:image/svg+xml;base64,PHN2ZyBzdHJva2U9ImN1cnJlbnRDb2xvciIgZmlsbD0iYmxhY2siIHN0cm9rZS13aWR0aD0iMCIgdmlld0JveD0iMCAwIDE2IDE2IiBoZWlnaHQ9IjMyIiB3aWR0aD0iMzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQuMTQ2LjE0NkEuNS41IDAgMCAxIDQuNSAwaDdhLjUuNSAwIDAgMSAuNS41YzAgLjY4LS4zNDIgMS4xNzQtLjY0NiAxLjQ3OS0uMTI2LjEyNS0uMjUuMjI0LS4zNTQuMjk4djQuNDMxbC4wNzguMDQ4Yy4yMDMuMTI3LjQ3Ni4zMTQuNzUxLjU1NUMxMi4zNiA3Ljc3NSAxMyA4LjUyNyAxMyA5LjVhLjUuNSAwIDAgMS0uNS41aC00djQuNWMwIC4yNzYtLjIyNCAxLjUtLjUgMS41cy0uNS0xLjIyNC0uNS0xLjVWMTBoLTRhLjUuNSAwIDAgMS0uNS0uNWMwLS45NzMuNjQtMS43MjUgMS4xNy0yLjE4OUE1LjkyMSA1LjkyMSAwIDAgMSA1IDYuNzA4VjIuMjc3YTIuNzcgMi43NyAwIDAgMS0uMzU0LS4yOThDNC4zNDIgMS42NzQgNCAxLjE3OSA0IC41YS41LjUgMCAwIDEgLjE0Ni0uMzU0eiI+PC9wYXRoPjwvc3ZnPg==")
      16 16,
    pointer;
}
.cursor2 {
  cursor: url("data:image/svg+xml;base64,PHN2ZyBzdHJva2U9ImN1cnJlbnRDb2xvciIgZmlsbD0iYmxhY2siIHN0cm9rZS13aWR0aD0iMCIgdmlld0JveD0iMCAwIDI0IDI0IiBhcmlhLWhpZGRlbj0idHJ1ZSIgaGVpZ2h0PSIzMiIgd2lkdGg9IjMyIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Ik0yMS43MzEgMi4yNjlhMi42MjUgMi42MjUgMCAwMC0zLjcxMiAwbC0xLjE1NyAxLjE1NyAzLjcxMiAzLjcxMiAxLjE1Ny0xLjE1N2EyLjYyNSAyLjYyNSAwIDAwMC0zLjcxMnpNMTkuNTEzIDguMTk5bC0zLjcxMi0zLjcxMi0xMi4xNSAxMi4xNWE1LjI1IDUuMjUgMCAwMC0xLjMyIDIuMjE0bC0uOCAyLjY4NWEuNzUuNzUgMCAwMC45MzMuOTMzbDIuNjg1LS44YTUuMjUgNS4yNSAwIDAwMi4yMTQtMS4zMkwxOS41MTMgOC4yeiI+PC9wYXRoPjwvc3ZnPg==")
      16 16,
    pointer;
}
.best-pratice-ex {
  margin-left: auto;
  margin-right: 0;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.canvas-class {
  width: 99%; // or
  width: 750px;
  display: flex;
  justify-content: center;
  margin: auto;
}
canvas {
  border: 10px solid #f5f5f5;
  border-radius: 10px;
  display: flex;
  width: 99%; // or
}
.fc-scroller {
  overflow: hidden !important;
}
.fc-col-header {
  margin-bottom: 1em !important;
}
table table.fc-col-header th {
  text-align: left !important;
  padding: 0 !important;
  font-family: Rubik !important;
  font-weight: bold;
  font-size: 14px !important;
}
.fc-daygrid-day-frame.fc-scrollgrid-sync-inner {
  display: flex !important;
  flex-direction: column-reverse !important;
  height: 100%;
}
.fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-top {
  height: 20%;
  font-family: "Rubik";
  font-weight: bold;
}
.fc-daygrid-day-frame.fc-scrollgrid-sync-inner .fc-daygrid-day-events {
  height: 80%;
}
.essence strong {
  display: block;
}

.bestPracticeExamples {
  background-color: black;
}