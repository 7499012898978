body {
  background: #fff;
  margin: 0;
}
/* .row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 0;
  margin-left: 0;
} */
p {
  color: #000;
  font-family: "Pridi", "Pridi-light", serif;
}
.footer-btn {
  margin-bottom: 50px;
}
.page {
  display: block;
  margin: 0 auto;
  letter-spacing: 0.2px;
  page-break-after: always;
  padding: 23px;
  width: 796px;
  height: 549px;
  position: relative;
  background: #fff;
}
/* .start {
  height: 554px;
}
.page2 {
  height: 549px;
} */
.page .inner {
  padding: 94px 50px 110px 70px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#page10 .inner {
  /* background: #283487; */
  /* height: 430px; */
  padding: 360px 50px 0 80px;
}

#page10 .inner.d-flex {
  align-items: flex-end;
}

#page10 footer {
  font-family: "Rubik", "Rubik-Light", sans-serif;
  font-size: 10px;
  height: 45px;
  padding: 10px 25px 14px 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  /* position: absolute;
  left: 0;
  right: 0;
  bottom: 0; */
}

/* .page[size="A4"][layout="landscape"] {
  width: 842px;
  height: 595px;
  position: relative;
  background: #fff;
} */

.page .inner-1 > img {
  position: absolute;
  top: 20px;
  right: 20px;
}

.page .inner-2 {
  background: #ffffff;
  height: 410px;
  padding: 58px 50px 76px 50px;
  position: relative;
}

.page .inner-2 > img {
  position: absolute;
  top: 20px;
  right: 20px;
}

.page .inner-2 h3 {
  font-size: 32px;
  color: #000000;
  font-weight: 300;
  line-height: 1.3;
}

.page .inner-2 h1 {
  font-size: 32px;
  color: #000;
  line-height: 1;
  font-weight: 300;
  padding-top: 20px;
}

.page .inner-2 h2 {
  font-size: 24px;
  color: #134393;
  font-weight: 300;
  max-width: 480px;
}

.page .inner-2 ul {
  padding: 0 16px;
  line-height: 21px;
}

.page .inner-2 ul li {
  font-family: "Rubik-Light", sans-serif;
  font-size: 12px;
}

.page .inner-2 p {
  font-family: "Rubik", "Rubik-Regular", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #1d1d1b;
  line-height: 1.48;
  margin-bottom: 6px;
  letter-spacing: 0.09px;
}
.page .inner-2 p span {
  font-weight: 700;
  font-family: "Rubik", "Rubik-Bold", sans-serif;
  color: #1d1d1b;
}
.page .inner-2 label {
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 700;
  font-family: "Rubik", "Rubik-Bold", sans-serif;
  color: #1d1d1b;
}

.page .inner-2 .d-flex {
  justify-content: space-between;
  align-items: start;
  margin-bottom: 9px;
}

.page .inner-2 .d-flex .column {
  padding-right: 20px;
}

/* content inner */
.page .content-page-inner {
  background: #ffffff;
  height: 517px;
  padding: 30px 47px 76px 50px;
  position: relative;
}

.page .content-page-inner-graph {
  padding: 0px 47px 76px 50px;
}

.page .content-page-inner > img {
  position: absolute;
  top: 20px;
  right: 20px;
}

.page .content-page-inner h3 {
  font-size: 22px;
  color: #134393;
  font-weight: 300;
  line-height: 1.3;
}

.page .content-page-inner h1 {
  font-size: 32px;
  color: #000;
  line-height: 1;
  font-weight: 300;
  padding-top: 20px;
}

.page .content-page-inner h2 {
  font-size: 24px;
  color: #134393;
  font-weight: 300;
  max-width: 480px;
}
.page .content-page-inner h5 {
  font-size: 32px;
  color: #000000;
  line-height: 32px;
  font-weight: 300;
  margin-bottom: 24px;
}

.page .content-page-inner ul {
  padding: 0 16px;
  line-height: 21px;
}

.page .content-page-inner ul li {
  font-family: "Rubik", sans-serif;
  font-size: 12px;
}

.page .content-page-inner p {
  font-family: "Rubik", "Rubik-Regular", sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #1d1d1b;
  line-height: 1.48;
  margin-bottom: 6px;
  letter-spacing: 0.09px;
}
.page .content-page-inner p span {
  font-weight: 700;
  font-family: "Rubik", "Rubik-Bold", sans-serif;
  color: #1d1d1b;
}
.page .content-page-inner label {
  margin-bottom: 4px;
  font-size: 13px;
  font-weight: 700;
  font-family: "Rubik", "Rubik-Bold", sans-serif;
  color: #1d1d1b;
}

.page .content-page-inner .d-flex {
  justify-content: space-between;
  align-items: start;
  margin-bottom: 52px;
}

.page .content-page-inner .d-flex .column {
  width: 338px;
}

.content-innner-fixed footer {
  font-family: "Rubik", "Rubik-Light", sans-serif;
  font-size: 10px;
  padding: 0 50px 0 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.content-innner-fixed footer span {
  border-top: 1px solid #134393;
  display: block;
  padding: 6px 0 10px 0;
  color: #000;
}
/* END content inner */

.page2 footer {
  font-family: "Rubik", "Rubik-Light", sans-serif;
  font-size: 10px;
  padding: 0 50px 0 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.page2 footer span {
  border-top: 1px solid #134393;
  display: block;
  padding: 7px 0 10px 0;
  color: #000;
}
.innner-fixed footer {
  font-family: "Rubik", "Rubik-Light", sans-serif;
  font-size: 10px;
  padding: 0 50px 0 50px;
  position: absolute;
  bottom: 0;
}
.innner-fixed footer span {
  border-top: 2px solid #134393;
  display: block;
  padding: 7px 0 10px 0;
  color: #000;
}
.d-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page .inner-1 h1 {
  font-size: 60px;
  color: #134393;
  font-weight: 300;
  line-height: 60px;
  margin-bottom: 0;
  letter-spacing: -1.3px;
}

.page .inner-4 {
  height: 443px;
  padding: 76px 50px 76px 50px;
  position: relative;
}

.page .inner-4 > img {
  position: absolute;
  top: 20px;
  right: 20px;
}

.page .inner-4 h1 {
  font-size: 60px;
  color: #134393;
  font-weight: 300;
  line-height: 1;
}

.page .inner-7 {
  background: #c1e3e6;
  height: 443px;
  padding: 76px 50px 76px 50px;
  position: relative;
}

.page .inner-7 h1 {
  font-size: 60px;
  color: #134393;
  font-weight: 300;
  line-height: 1;
}

.page .inner-1 h2 {
  font-size: 22px;
  color: #134393;
  font-weight: 300;
  line-height: 1.3;
  margin-top: 8px;
  margin-left: 3px;
}
.agency-logo-top {
  position: absolute;
  top: 20px;
  right: 20px;
  max-width: 78px;
  max-height: 56px;
  z-index: 9;
}
.agency-logo-top-content {
  position: absolute;
  top: 20px;
  right: 20px;
  max-width: 78px;
  max-height: 56px;
  z-index: 9;
}

.page .inner-5 {
  height: 411px;
  padding: 76px 50px 76px 50px;
  position: relative;
}

.page .inner-5 > img {
  position: absolute;
  top: 20px;
  right: 20px;
}

.page .inner-5 h1 {
  font-size: 60px;
  color: #134393;
  font-weight: 300;
}

.page .inner-5 h2 {
  font-size: 24px;
  color: #134393;
  font-weight: 300;
  max-width: 480px;
  line-height: 1;
}

#page5 footer {
  font-family: "Rubik-Light", sans-serif;
  font-size: 10px;
  padding: 0 50px 0 50px;
}

#page5 footer span {
  display: block;
  padding: 10px 0 10px 0;
}

.page .inner-8 ul {
  padding: 0;
}

.page .inner-8 {
  background: #c1e3e6;
  height: 411px;
  padding: 76px 50px 76px 50px;
  position: relative;
}

.page .inner-8 > img {
  position: absolute;
  top: 20px;
  right: 20px;
}

.page .inner-8 h1 {
  font-size: 60px;
  color: #134393;
  font-weight: 300;
}

.page .inner-8 h2 {
  font-size: 24px;
  color: #134393;
  font-weight: 300;
  max-width: 480px;
  line-height: 1;
}

.page .inner-8 ul li {
  line-height: 40px;
  font-size: 24px;
  color: #134393;
  font-weight: 300;
  max-width: 480px;
  list-style-type: none;
  font-family: "Pridi", "Pridi-light", serif;
}

#page8 footer {
  font-family: "Rubik-Light", sans-serif;
  font-size: 10px;
  background: #c1e3e6;
  padding: 0 50px 0 50px;
}

#page8 footer span {
  display: block;
  padding: 10px 0 10px 0;
}

/*--------------------------------------------------------------*/

/*page 9 css*/

#page9 h1 {
  font-size: 26px;
  color: #0a4fa0;
  margin-bottom: 9px;
  margin-left: 12px;
}

.bar {
  float: left;
  width: 21.9%;
}

.bar_left,
.bar_right {
  float: left;
  width: 159px;
}

.bar_left .inner_bar {
  height: 485px;
  overflow: hidden;
}

.bar_right .inner_bar {
  height: 295px;
}

.inner_bar {
  background: #d6e2e6;
  padding: 15px;
}

.center_box {
  float: left;
  width: 465px;
  margin: 0 5px;
}

.center_box2 {
  float: left;
  width: 54%;
  margin: 0 5px;
  position: relative;
}

.center_inner {
  background: #fad3e2;
}

#page9 h6 {
  color: #76787b;
  font-size: 8px;
  margin-bottom: 0;
  letter-spacing: 0;
}

#page9 p,
#page9 ul li {
  font-family: "Rubik", "Rubik-Regular", sans-serif;
  font-size: 8px;
  max-width: 400px;
  margin-bottom: 4px;
  margin-top: 0;
  line-height: 1;
}
.center_text {
  float: left;
  width: 40%;
  margin: 0 18px;
}
.center_inner {
  background: #fad3e2;
  height: 116px;
  display: table;
  padding: 7px;
  width: 100%;
  padding-left: 8px;
}

.center_inner.bottom {
  background: #d7dd98;
}

#page9 .center_text p {
  line-height: 1;
}

.center_inner.yellow_box {
  background: #fded76;
  height: 109px;
  margin: 5px 0;
  width: 236px;
}

.center_inner.yellow_box .center_text {
  /* margin: 0 10px; */
}

.center_inner.yellow_box .center_text ul li {
  line-height: 24px;
  list-style-type: none;
  line-height: 1;
  font-weight: 700;
  font-family: "Rubik", "Rubik-Bold", sans-serif;
  color: #1d1d1b;
}

.center_inner.yellow_box .center_text ul {
  margin: 0;
  padding: 0;
}

#page9 h6.wrap {
  width: 100%;
  float: left;
  margin: 0;
}

.round {
  z-index: 9;
  background: #fff;
  border-radius: 50%;
  height: 94px;
  width: 104px;
  text-align: center;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(63%, -49%);
  -webkit-transform: translate(63%, -49%);
  -moz-transform: translate(63%, -49%);
  -o-transform: translate(63%, -49%);
}

#page9 .round p {
  font-size: 8px;
  margin-top: 5px;
  font-weight: 400;
  font-family: Rubik, Rubik-Regular, sans-serif;
}

#page9 .round h6 {
  color: #76787b;
  font-size: 8px;
  margin: 13px 0 0;
}

.center_box .inner_bar {
  height: 262px;
  background: #b3dde0;
  margin-top: 5px;
}

.center_box .inner_bar p {
  margin: 8px 0;
}

.top_15 {
  display: inline-block;
}

/*page 9 css end*/

h1,
h2,
h3,
h5,
h6 {
  font-family: "Pridi", "Pridi-light", serif;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 75px;
  color: #e83c56;
  font-weight: 300;
  letter-spacing: -1.3px;
  margin-bottom: 6px;
}

h2 {
  font-size: 36px;
  color: #ffffff;
  line-height: 1.2;
  font-weight: 300;
}

img {
  max-width: 100%;
}
/* .start {
  position: relative;
  width: 796px;
} */
.start footer {
  font-family: "Rubik", "Rubik-Light", sans-serif;
  font-size: 10px;
  height: 74px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  padding: 0;
}
.start .footer-inner {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px 25px 14px 50px;
}
.blue-background-image {
  width: 100%;
}
/* .pdf-report footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  font-family: "Rubik", "Rubik-Light", sans-serif;
  font-size: 10px;
  padding: 0 50px 0 50px;
} */
footer img {
  max-width: 85px;
  max-height: 46px;
}
.yellow {
  position: relative;
  background-color: #ffed71;
  height: 100%;
  width: 100%;
}
.white {
  height: 100%;
  position: relative;
  background-color: #ffffff;
}
.light-pink {
  height: 100%;
  position: relative;
  background-color: #fadae9;
}
.pale-blue {
  height: 100%;
  position: relative;
  background-color: #c1e3e6;
}
.bronze {
  height: 100%;
  position: relative;
  background-color: #de9b7d;
}
.green {
  height: 100%;
  position: relative;
  background-color: #dfdf9c;
}
.jade {
  height: 100%;
  position: relative;
  background-color: #89c0ba;
}
.agency-logo-inner {
  max-width: 78px;
}
table {
  margin-bottom: 6em;
  margin-left: 6px;
}
table tr td {
  padding: 0.3em;
  padding-top: 3px;
  padding-bottom: 4px;
  padding-left: 1.2em;
  padding-right: 1.2em;
  border-bottom: 1px solid #ccc;
  text-align: right;
  border-right: 1px solid #ccc;
  font-size: 12px;
}
table tr th {
  padding: 0.3em;
  padding-left: 1.2em;
  padding-right: 1.2em;
  border-bottom: 1px solid #ccc;
  font-size: 12px;
  text-align: left;
  line-height: 13px;
  font-weight: 300;
  font-family: "Rubik", "Rubik-Light", sans-serif;
}
table tr th span.abbreviation {
  display: none;
}
table tr th.population,
table tr td.population {
  max-width: 99px;
  padding-left: 15px;
  padding-right: 19px;
}
table tr th.population,
table tr th.share-of-attribute,
table tr th.share-of-item {
  font-size: 14px;
}
table tr th.share-of-attribute,
table tr td.share-of-attribute {
  max-width: 86px;
  padding-left: 15px;
  padding-right: 13px;
}
table tr th.share-of-item,
table tr td.share-of-item {
  max-width: 70px;
  padding-left: 11px;
  padding-right: 13px;
}

table tr td:first-child,
table tr th:first-child {
  font-family: "Rubik", "Rubik-Light", sans-serif;
  font-weight: 300;
  /* width: 305px; */
  text-align: left;
  font-size: 12px;
}
table tr th:first-child {
  padding-left: 22px;
  font-family: "Rubik", "Rubik-Light", sans-serif;
  font-weight: 300;
  font-size: 14px;
  text-align: left;
  line-height: 14px;
}
table tr td:first-child {
  border-left: 1px solid #ccc;
}
caption {
  display: block;
  text-align: center;
}
.logos {
  position: absolute;
  top: 20px;
  right: 141px;
  max-width: 113px;
  z-index: 9;
}
.logos img {
  max-height: 3em;
}
.logos .logos-short img {
  max-height: 3em;
}
.competitors-list {
  padding: 10px 0px 10px;
  border-bottom: 1px solid #d3dce6;
}
.competitors-list span {
  text-align: right;
}
.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.col-md-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}
.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}
.competitor-block {
  display: flex;
  justify-content: flex-start;
  -moz-column-count: 3;
  -moz-column-gap: 30px;
  column-gap: 30px;
  padding-right: 15px;
  flex-wrap: wrap;
}
.competitors-list {
  padding: 4px 0 4px;
  border-bottom: 1px solid #d3dce6;
  width: 21%;
  min-height: 74px;
}
.competitors-list img {
  height: 35px;
  width: auto;
  max-width: 250px;
  margin-bottom: 6px;
}
.competitors-list p {
  font-weight: 700;
  font-family: "Rubik", "Rubik-Bold", sans-serif;
  color: #1d1d1b;
  font-size: 12px;
  line-height: 1.48;
}
.justify-content-between {
  justify-content: space-between !important;
}
.col-lg-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
.y-axis-label {
  text-align: center;
  font-family: "Rubik", "Rubik-Light", sans-serif;
}
.matrix-wrapper {
  padding: 12px;
  position: relative;
}
.matrix-wrapper .matrix {
  position: relative;
  min-height: 330px;
  z-index: 2;
}
.matrix-wrapper .matrix .y-axis {
  top: 0px;
  left: 0px;
  width: 50%;
  position: absolute;
  height: 100%;
  z-index: 1;
  border-right: 1px solid #000000;
}
.matrix-wrapper .matrix .x-axis {
  left: 0px;
  top: 50%;
  width: 100%;
  position: absolute;
  height: 50%;
  z-index: 1;
  text-align: right;
  border-top: 1px solid #000000;
  font-family: "Rubik", "Rubik-Light", sans-serif;
}
.matrix-wrapper .matrix .x-axis span {
  line-height: 2em;
}
.matrix-wrapper .matrix .matrix-cell {
  width: 40px;
  font-size: 0.9em;
  height: 40px;
  /* margin-top: -2em;
    margin-left: -2em; */
  z-index: 2;
  position: absolute;
  text-align: center;
  line-height: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.matrix-wrapper .matrix .matrix-cell.left-margin-minus {
  margin-left: -40px;
}
.matrix-wrapper .matrix .matrix-cell.top-margin-minus {
  margin-top: -40px;
}
.matrix-wrapper .matrix .matrix-cell span {
  font-size: 11px;
  font-family: "Pridi", "Pridi-light", serif;
  color: #000;
  font-weight: 300;
  width: 100%;
}
.matrix-wrapper .matrix .matrix-cell img {
  width: auto;
  margin: auto;
  max-width: 40px;
  max-height: 40px;
}
.page-group {
  margin: 0 auto;
}
.background-intro {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.intro-section {
  width: 50%;
  padding: 0 40px 0 41px;
  margin-top: 56px;
}
.background-section {
  width: 50%;
}
.background-section img {
  width: auto;
  height: auto;
  max-height: 480px;
  margin-top: 28px;
  margin-right: 31px;
  float: right;
}
.background-intro h1 {
  font-size: 60px;
  color: #134393;
  font-weight: 300;
  line-height: 51px;
  margin-bottom: 24px;
}
.background-intro p {
  font-size: 18px;
  color: #134393;
  font-weight: 300;
  line-height: 1.26;
  margin-top: 8px;
  letter-spacing: 0.4px;
  max-width: 480px;
}
.persona {
  margin-top: 30px;
  width: 622px;
  margin-left: 3px;
}
.mat-card {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
  font-family: "Rubik", "Rubik-Light", sans-serif;
  font-weight: 300;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 11px;
  padding-left: 14px;
}
.mat-card:not([class*="mat-elevation-z"]) {
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%),
    0 1px 3px 0 rgb(0 0 0 / 12%);
}
.mat-card-title {
  display: block;
  margin-bottom: 6px;
  color: #2c4590;
  font-family: "Pridi", "Pridi-light", serif;
  font-weight: 300;
}
.mat-card-content,
.mat-card-subtitle {
  font-size: 11px;
}
.mat-card-subtitle {
  display: block;
  margin-bottom: 14px;
  color: #999288;
}
.mat-card .stats {
  padding-top: 15px;
}
.mat-card .mat-card-content .subtitle {
  padding-bottom: 0.5em;
  color: #999288;
}

.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa,
.fas {
  font-weight: 900;
}
.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free";
}
.fa-users:before {
  content: "\f0c0";
}
.fa-globe-africa:before {
  content: "\f57c";
}
.drop-item {
  font-family: "Pridi", "Pridi-light", serif;
  padding-top: 4px;
  color: #000;
}
.drop-item-margin {
  margin-top: 6px;
}
.heading {
  font-size: 12px;
  margin-bottom: 9px;
  color: #1d1d1b;
  font-weight: 700;
  font-family: "Rubik", "Rubik-Bold", sans-serif;
}
.archetype {
  padding-right: 14px;
}
.archetype img {
  display: block;
  width: 455px;
  margin-bottom: 15px;
}
.archetype h4 {
  font-family: "Pridi", "Pridi-light", serif;
  font-weight: 300;
  font-size: 1.3em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0px;
}
.archetype p {
  display: block;
  color: #1d1d1b;
  font-size: 12px;
  font-family: "Rubik", "Rubik-Regular", sans-serif;
  font-weight: 400;
}
.archetype p.approach {
  padding-bottom: 0.5em;
  margin-bottom: 1em;
}
.brand-strengths img {
  width: 483px;
  margin: 0;
  margin-left: 96px;
}
.table-stakes {
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.table-stakes img {
  max-width: 200px;
}
.table-stakes .drop-item {
  padding: 3px;
  text-align: center;
  margin-bottom: 4px;
}
.table-stakes .col-6 {
  text-align: center;
}
.personality h5 {
  font-size: 1.25rem;
  text-decoration: none;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  margin-top: 20px;
  font-family: "Pridi-Normal", sans-serif;
}
.brand-values {
  text-align: center;
  justify-content: space-between;
  display: flex;
  margin-top: 30px;
  font-family: "Rubik", "Rubik-Light", sans-serif;
}
.nopadding {
  padding: 0;
}
.nopaddingleft {
  padding-left: 0;
}
.brand-values .drop-item {
  min-height: 170px;
  text-align: left;
  border: 1px solid #cae2e5;
  padding: 4px;
}
.essence-container {
  position: relative;
  font-family: "Rubik", "Rubik-Light", sans-serif;
  color: #000;
  width: 80%;
  margin: 0 auto;
}
.essence-container .value,
.essence-container .essence {
  border: 1px solid #134393;
  position: absolute;
  height: 9em;
  width: 40%;
  font-family: "Rubik", "Rubik-Light", sans-serif;
}
.essence-container .essence {
  text-align: center;
  top: 7em;
  left: 55%;
  z-index: 2;
  width: 40%;
  background-color: #ffffff;
  margin-left: -25%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.essence-container .value {
  margin-bottom: 2em;
  text-align: center;
  right: 0%;
  font-family: "Rubik", "Rubik-Light", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
.essence-container .value.left {
  text-align: center;
  left: 0px;
}
.essence-container .value.bottom {
  top: 14em;
}
.essence-container .value.bottom {
  top: 14em;
}
.elevator-pitch {
  margin-top: 30px;
}
.elevator-pitch .drop-item {
  padding: 20px;
}
.elevator-pitch p {
  font-family: "Rubik", "Rubik-Light", sans-serif;
  font-size: 23px !important;
  color: #000;
  margin-bottom: 15px !important;
}
.page ul li {
  list-style: none;
  margin-top: 10px;
}
.page ul li {
  font-size: 22px;
  color: #134393;
  font-weight: 300;
  line-height: 1.3;
  font-family: "Pridi", "Pridi-light", serif;
}
.brand-presence h1 {
  margin-bottom: 30px;
}
.brand-presence p {
  color: #134393;
}
.main_box span {
  font-weight: 700;
  color: #76787b;
  font-weight: 700;
  font-family: "Rubik", "Rubik-Bold", sans-serif;
  color: #1d1d1b;
}
.canvas-chart {
  margin-top: 45px;
}

canvas.converted {
  display: none !important;
}
.radar-canvas-block img.radar-canvas {
  width: 600px !important;
  height: 294px !important;
  margin-top: 11px !important;
  display: block !important;
}
.large-copy {
  position: absolute;
  left: 47px;
  top: 48px;
  max-width: 635px;
}
.large-copy p {
  font-size: 18px;
  color: #134393;
  font-weight: 300;
  line-height: 1.26;
  margin-top: 8px;
  letter-spacing: 0.4px;
  margin-bottom: 0;
}
.large-copy ul li {
  font-size: 18px;
  color: #134393;
  font-weight: 300;
  line-height: 1.26;
  font-family: "Pridi", "Pridi-light", serif;
}
